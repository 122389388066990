
.swiper-pagination-bullets{
    bottom: revert !important;
    top: -10px;
    left: 0;
    width: 100%
}
/*.css-1k9upfx-MuiTypography-root{*/
/*    font-size: 0.7rem !important;*/
/*}*/
/*.css-wa3mca-MuiTypography-root{*/
/*    font-size: 0.7rem !important;*/
/*}*/
/*.css-p37scc-MuiTypography-root{*/
/*    font-size: 0.7rem !important;*/
/*}*/
/*.css-p2vi21-MuiTypography-root{*/
/*    font-size: 0.7rem !important;*/
/*}*/
/*.css-1dx2ob-MuiTypography-root{*/
/*    font-size: 0.7rem !important;*/
/*}*/
/*.css-1vfbe0h-MuiTypography-root{*/
/*    font-size: 0.7rem !important;*/
/*}*/
/*.css-x1el3i-MuiTypography-root{*/
/*    font-size: 0.7rem !important;*/
/*}*/